import React, { Component, Fragment } from 'react'
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from '../../../Utils/Cards/cardSimple';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import { RenderMoneda, RenderDateTime } from "../../../Utils/renderField/renderTableField";
import { Monedas, getsimbolo } from "../../../../../utility/constants";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import ImageVer from '../../../../../../assets/img/icons/Ver.png';
import { Link, Redirect } from 'react-router-dom';

function cellTachado(cell, row) {
    if (!row.estado) {
        return { textDecoration: "line-through", whiteSpace: 'normal', padding: 5 }
    }
    return { whiteSpace: 'normal', padding: 5 }
}


const ListadoT = (props) => {
    const { onPageChange, data, loader, open_modal, open_modal_ver, permisos, page, nombre_empresa, moneda } = props;
    let TARJETA_TRANSACCION = 5;

    return (
        <Fragment>
            <Grid hover
                striped data={data}
                loading={loader}
                page={page}
                onPageChange={props.onChangePage}  >
                {/* <TableHeaderColumn
                                dataSort
                                // width='125'
                                dataField="tipo"
                                dataFormat={(cell)=><span>{props.getMovimiento(cell)}</span>}
                            >
                                MOVIMIENTO
                            </TableHeaderColumn> */}
                <TableHeaderColumn
                    isKey
                    dataField="id"
                    dataAlign="center"
                    dataSort
                    tdStyle={{ whiteSpace: 'normal' }}
                    dataFormat={(cell, row) => {
                        const abonosRelacionados = row.abonos_relacionados;
                        let showButtonDelete = row.tipo==10 && row.debe > 0 ? false : true;
                        const esReintegro = row.transaccion && row.transaccion.tipo_transaccion == 11 ? true : false;

                        if (esReintegro) {
                            return standardActions({
                                eliminarModal: props.abrirModalEliminar,
                                verpagosModal: props.abrirModalPagosReintegro,
                            })(cell, row);
                        } else if (abonosRelacionados && abonosRelacionados.length > 0) {
                            const hayAlMenosUnNoFactura = abonosRelacionados.some(abono => !abono.no_factura_rel);
                            if (!hayAlMenosUnNoFactura) {
                                return standardActions({
                                    eliminarModal: showButtonDelete ? props.abrirModalEliminar : undefined,
                                    adicional: props.botonModal,
                                    adicional2: props.botonModalDetalle,
                                    verpagosModal: props.abrirModalPagos,
                                })(cell, row);
                            } else if (hayAlMenosUnNoFactura) {
                                return standardActions({
                                    eliminarModal: showButtonDelete ? props.abrirModalEliminar : undefined,
                                    adicional: props.botonModal,
                                    adicional2: props.botonModalDetalle,
                                    verpagosModal: props.abrirModalPagos,
                                    adicional6: props.botonAlerta,
                                    alertModal: props.abrirModalAlerta,
                                })(cell, row);
                            } else {
                                return standardActions({
                                    eliminarModal: props.abrirModalEliminar,
                                    adicional: props.botonModal,
                                })(cell, row);
                            }
                        } else if (row.tipo == 10 && !row.es_ingreso) {
                            // Es Despacho: Sin acciones.
                            return standardActions({})(cell, row);
                        } else if (row.no_despacho && !row.es_ingreso) {
                            return standardActions({
                                eliminarModal: props.abrirModalEliminar,
                                adicional: props.botonModal,
                            })(cell, row);
                        } else {
                            return standardActions({
                                eliminarModal: props.abrirModalEliminar,
                                adicional: props.botonModal,
                            })(cell, row);
                        }
                    }}
                ></TableHeaderColumn>
                <TableHeaderColumn
                    dataField="transaccion"
                    width='300'
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat={
                        (cell, row) => (
                            cell ? (
                                <div>
                                    {
                                        row.tipo && (
                                            <div>
                                                <span>{() => props.getMovimiento(row.tipo)}</span>
                                            </div>
                                        )
                                    }
                                    {
                                        row.cliente && (
                                            <Fragment>
                                                <span className="font-weight-bold">Cliente: </span>
                                                <span style={{ color: "black" }} >{row.cliente}</span>
                                                <br />
                                            </Fragment>
                                        )
                                    }
                                    {
                                        cell.label_tipo_transaccion && (
                                            <div>
                                                <span className="font-weight-bold">Transaccion: </span>
                                                <span className="t-musgo ">{cell.label_tipo_transaccion}</span><br />
                                            </div>
                                        )
                                    }
                                    {
                                        ((cell.label_tipo_transaccion && cell.tipo_documento == TARJETA_TRANSACCION) || (row.transaccion_anterior && row.transaccion_anterior.tipo_documento == TARJETA_TRANSACCION)) && (
                                            <div>
                                                <span className="font-weight-bold">Cuotas: </span>
                                                <span className="t-musgo ">{cell.cantidad_cuotas_tarjeta}</span><br />
                                                <span className="font-weight-bold">POSS: </span>
                                                <span className="t-musgo ">{row.transaccion_anterior ? row.transaccion_anterior.poss_string : cell.poss_string}</span><br />
                                            </div>
                                        )
                                    }
                                    {
                                        (row.transaccion_anterior && row.transaccion.tipo_documento != 6 && !row.flotante && !row.no_despacho) ?
                                            <div>
                                                <span className="font-weight-bold">No. documento: </span>
                                                <span className="t-musgo ">{row.transaccion_anterior.no_documento}</span><br />
                                                <span className="font-weight-bold">No. documento (nuevo): </span>
                                                <span className="t-musgo ">{cell.no_documento}</span><br />
                                            </div>
                                            :
                                            cell.no_documento && (
                                                <div>
                                                    <span className="font-weight-bold">No. documento: </span>
                                                    <span className="t-musgo ">{cell.no_documento}</span><br />
                                                </div>
                                            )

                                    }
                                    {
                                        cell.tipo_documento_pago && (
                                            <div>
                                                <span className="font-weight-bold">Tipo de documento: </span>
                                                <span className="t-musgo ">{cell.tipo_documento_pago}</span>
                                            </div>
                                        )
                                    }
                                    {
                                        row.no_factura != "" && (
                                            <div>
                                                <span className="font-weight-bold">No. factura: </span>
                                                <span className="t-musgo ">{row.no_factura}</span><br />
                                            </div>
                                        )
                                    }
                                    {
                                        row.factura_data_string && row.factura_data_string.nombres && (
                                            <div>
                                                <span className="font-weight-bold">Factura nombre: </span>
                                                <span className="t-musgo ">{row.factura_data_string.nombres}</span><br />
                                            </div>
                                        )
                                    }
                                    {
                                        row.factura_data_string && row.factura_data_string.nits && (
                                            <div>
                                                <span className="font-weight-bold">Factura NIT: </span>
                                                <span className="t-musgo ">{row.factura_data_string.nits}</span><br />
                                            </div>
                                        )
                                    }
                                    {
                                        cell.nombre_tipo_gasto && (
                                            <div>
                                                <span className="font-weight-bold">Tipo de Gasto: </span>
                                                <span className="t-musgo ">{cell.nombre_tipo_gasto}</span>
                                            </div>
                                        )
                                    }
                                    {
                                        (row.transaccion_anterior && row.transaccion.tipo_documento != 6 && !row.flotante && !row.no_despacho) ? (
                                            <div>
                                                <span className="font-weight-bold">Descripcion: </span>
                                                <span className="t-musgo ">{row.transaccion_anterior.descripcion}</span><br />
                                            </div>
                                        )
                                            :
                                            <div>
                                                <span className="font-weight-bold">Descripcion: </span>
                                                <span className="t-musgo ">{cell.descripcion}</span><br />
                                            </div>
                                    }
                                    {
                                        cell.empresa_nombre && (
                                            <div>
                                                <span className="font-weight-bold">Empresa: </span>
                                                <span className="t-musgo ">{cell.empresa_nombre}</span>
                                            </div>
                                        )
                                    }
                                    {
                                        cell.nombre_proveedor && (
                                            <div>
                                                <span className="font-weight-bold"> Proveedor: </span>
                                                <span className="t-musgo"> {cell.nombre_proveedor} </span>
                                            </div>
                                        )
                                    }
                                    {
                                        cell.orden && (
                                            <Fragment>
                                                <div style={{display: 'flex'}}>
                                                    <div>
                                                        <span className="font-weight-bold">Orden: </span>
                                                        { cell.orden }
                                                    </div>
                                                    {
                                                        (row.transaccion.despacho && row.transaccion.despacho.orden) && (
                                                            <div>
                                                                &nbsp;&nbsp;
                                                                <Link className="" to={`/ordenesventa/${row.transaccion.despacho.orden ? row.transaccion.despacho.orden.id : 0}/detalle`}>
                                                                    <img className="action-img-ver" title="ver" src={ImageVer} alt="ver" />
                                                                </Link>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </Fragment>
                                        )
                                    }
                                    {
                                        (row.transaccion && row.transaccion.dte && row.transaccion.dte.no_despacho_origen) && (
                                            <Fragment>
                                                <div style={{display: 'flex'}}>
                                                    <div>
                                                        <span className="font-weight-bold">Despacho: </span>
                                                        { row.transaccion.dte.no_despacho_origen }
                                                    </div>
                                                    {
                                                        (row.transaccion.despacho && row.transaccion.despacho.orden) && (
                                                            <div>
                                                                &nbsp;&nbsp;
                                                                <Link className="" to={`/ordenesventa/${row.transaccion.despacho.orden ? row.transaccion.despacho.orden.id : 0}/detalle`}>
                                                                    <img className="action-img-ver" title="ver" src={ImageVer} alt="ver" />
                                                                </Link>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </Fragment>
                                        )
                                    }
                                    {
                                        row.no_despacho && (
                                            <div>
                                                <span className="font-weight-bold">despacho: </span>
                                                <span className="t-musgo ">{row.no_despacho}</span>
                                            </div>
                                        )
                                    }
                                    {
                                        (cell.moneda != Monedas[1].value) ? (
                                            <div>
                                                <span className="font-weight-bold">moneda: </span>
                                                <span className="t-musgo ">{cell.moneda}</span>
                                                <span className="font-weight-bold"> con tipo de cambio  </span>
                                                <span className="t-musgo ">
                                                    <RenderMoneda
                                                        monto={cell.tipo_cambio}
                                                        simbolo={getsimbolo(cell.moneda)}
                                                        decimalScale={4}
                                                    />
                                                </span>
                                            </div>
                                        ) : (
                                            <div>
                                                <span className="font-weight-bold">moneda: </span>
                                                <span className="t-musgo ">{cell.moneda}</span>
                                            </div>
                                        )
                                    }
                                    {
                                        row.devolucion !="" && (
                                            <div>
                                                <span className="font-weight-bold">Devolución: </span>
                                                <span className="t-musgo ">{row.devolucion}</span><br />
                                            </div>
                                        )
                                    }
                                    {
                                        (row.transaccion.fecha_cobro) && (
                                            <Fragment>
                                                <span className="font-weight-bold">Fecha a cobrar </span><RenderDateTime fecha={row.transaccion.fecha_cobro} ></RenderDateTime> <br />
                                            </Fragment>
                                        )
                                    }
                                    {
                                        (row.transaccion.banco_referencia) && (
                                            <Fragment>
                                                <span className="font-weight-bold">Banco de referencia: </span> {row.transaccion.banco_referencia} <br />
                                            </Fragment>
                                        )
                                    }
                                    {
                                        (row.flotante) && (
                                            <div>
                                                <span className="font-weight-bold">Es flotante: </span>
                                                <span className="t-musgo ">Sí, pendiente validación.</span>
                                            </div>
                                        )
                                    }
                                    {
                                        row.asignado_a && !row.transaccion.nombre_cliente && (
                                            <Fragment>
                                                <span className="font-weight-bold">Asignado a: </span>
                                                <span className="t-musgo ">{row.asignado_a}</span><br />
                                            </Fragment>
                                        )
                                    }
                                    {
                                        row.no_itinerario && (
                                            <Fragment>
                                                <span className="font-weight-bold">Itinerario: </span>
                                                <span className="t-musgo ">No. {row.no_itinerario}</span><br />
                                            </Fragment>
                                        )
                                    }
                                    {
                                        (row.fecha_asignacion && row.transaccion.tipo_documento != 6 && !row.flotante && !row.no_despacho) && (
                                            <Fragment>
                                                <span className="font-weight-bold">Fecha asignación </span> {row.fecha_asignacion} <br />
                                            </Fragment>
                                        )
                                    }
                                    {
                                        cell.es_pago_anticipado && (
                                            <div>
                                                <span className="font-weight-bold">Pago Anticipado</span>
                                            </div>
                                        )
                                    }
                                    {
                                        (row.transaccion && row.transaccion.dte) && (
                                            <Fragment>
                                                <div style={{display: 'flex'}}>
                                                    <div>
                                                        <span className="font-weight-bold">{row.transaccion.dte.tipo_dte_string}: </span>
                                                        {row.transaccion.dte.numero}
                                                    </div>
                                                    <div>
                                                        {
                                                            standardActions({
                                                                adicional:props.botonFactura
                                                            })(cell,row)
                                                        }
                                                    </div>

                                                </div>
                                            </Fragment>
                                        )
                                    }
                                </div>
                            ) : " NO HAY INFORMACION "
                        )
                    }
                >
                    DETALLE TRANSACCION
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="fecha"
                    // width='100'
                    tdStyle={cellTachado}
                    dataSort
                    dataFormat={
                        (cell, row) => (
                            <RenderDateTime fecha={cell} />
                        )
                    }
                >
                    FECHA
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="debe"
                    dataAlign="right"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat={
                        (cell, row) => (
                            <RenderMoneda monto={cell} simbolo={getsimbolo(moneda)} />
                        )
                    }
                >
                    DEBE
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="haber"
                    dataAlign="right"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat={
                        (cell, row) => (
                            (row.transaccion && row.transaccion.tipo_documento==TARJETA_TRANSACCION) ?
                            <RenderMoneda monto={row.monto_recibido} simbolo={getsimbolo(moneda)} /> : <RenderMoneda monto={cell} simbolo={getsimbolo(moneda)} />
                        )
                    }
                >
                    HABER
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="saldo"
                    dataAlign="right"
                    dataSort
                    tdStyle={cellTachado}
                    dataFormat={
                        (cell, row) => (
                            <RenderMoneda monto={(Number.parseFloat(`${cell}`).toFixed(2) == 0) ? Math.abs(Number.parseFloat(`${cell}`).toFixed(2)) : Number.parseFloat(`${cell}`).toFixed(2)} simbolo={getsimbolo(moneda)} />
                        )
                    }

                >
                    SALDO
                </TableHeaderColumn>
            </Grid>
        </Fragment>
    )
};

export default ListadoT;
